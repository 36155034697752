import { useBasket } from '@/src/packages/components/contexts/basket.context';
import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { Box, IBoxProps } from '@/src/packages/components/ui/Box';
import { Country } from '@/src/packages/components/ui/Country';
import { Heading } from '@/src/packages/components/ui/Heading';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
} from '@/src/packages/components/ui/Sheet';
import { Stack } from '@/src/packages/components/ui/Stack';
import { IFetchShippingDestinations } from '@bitmap/contracts';
import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { useLocaleDrawer } from '@/src/packages/hooks/use-basket-drawer';
import { VariantProps, tv } from 'tailwind-variants';
import { Column } from '@/src/packages/components/ui/GridBox';
import { Divider } from '@/src/packages/components/ui/Divider';
import { Loader } from '@/src/packages/components/ui/Loader';

const itemStyles = tv({
  base: 'hover:text-text-primary text-text-secondary h-8 w-full cursor-pointer text-left focus-visible:text-text-primary focus-visible:outline-none',
  variants: {
    isSelected: {
      true: 'text-text-primary',
    },
  },
});

const Item = (props: IBoxProps & VariantProps<typeof itemStyles>) => {
  const { isSelected, className, ...rest } = props;

  return (
    <Box
      as="button"
      className={itemStyles({ isSelected, className })}
      {...rest}
    />
  );
};

const CountryOption = ({
  item,
  handleClick,
}: {
  item: IFetchShippingDestinations[0];
  handleClick?(): void;
}) => {
  const { shippingCountry } = useStoreContext();

  const isSelected = item.iso2.toLowerCase() === shippingCountry?.toLowerCase();

  return (
    <Item onClick={handleClick} isSelected={isSelected}>
      {item.country}
    </Item>
  );
};

const CurrencySelector = () => {
  const { currencies, currencyCode } = useStoreContext();

  const { setCurrency } = useBasket();

  const value = currencies.find((currency) => currency.iso === currencyCode)
    ?.iso;

  const handleChange = async (value: string) => {
    setCurrency(value);
  };

  return (
    <Stack spaceY={4}>
      <Heading as="h3" fontWeight="medium">
        Currency:{' '}
        <Box as="span" className="font-normal">
          {currencyCode}
        </Box>
      </Heading>
      <Stack>
        {currencies.map((item, index) => {
          const isSelected = value === item.iso;

          return (
            <Item
              key={index}
              onClick={() => handleChange(item.iso)}
              isSelected={isSelected}
            >
              {item.symbol} {item.iso}
            </Item>
          );
        })}
      </Stack>
    </Stack>
  );
};

const CountrySelector = () => {
  const { shippingDestinations, shippingCountry } = useStoreContext();

  const { setDeliveryAddressCountry } = useBasket();

  const country = shippingDestinations.find(
    (country) => country.iso2.toLowerCase() === shippingCountry?.toLowerCase(),
  );

  const [items, setItems] =
    useState<IFetchShippingDestinations>(shippingDestinations);

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length > 0) {
      const result = sortBy(
        shippingDestinations.filter(
          (item) => item.country?.toLowerCase().includes(query.toLowerCase()),
        ),
        (item) => item.country,
      );

      setItems(result);
    } else {
      setItems(shippingDestinations);
    }
  }, [query, shippingCountry, shippingDestinations]);

  const handleClick = (countryIso: string) => {
    setDeliveryAddressCountry({ countryIso });
  };

  const featuredCountriesIso = ['GB', 'US', 'AU', 'NZ', 'CA', 'FR', 'DE'];

  const feauturedCountries = items
    .filter((c) => featuredCountriesIso.includes(c.iso2))
    .sort(
      (a, b) =>
        featuredCountriesIso.indexOf(a.iso2) -
        featuredCountriesIso.indexOf(b.iso2),
    );

  const otherCountries = items.filter(
    (c) => !featuredCountriesIso.includes(c.iso2),
  );

  return (
    <Box className="flex flex-1 flex-col overflow-hidden">
      <Stack spaceY={4} className="pb-5">
        <Stack spaceY={2}>
          <Heading as="h3" fontWeight="medium">
            Delivery country:{' '}
            <Box as="span" className="font-normal">
              {country?.country}
            </Box>
          </Heading>

          <Paragraph className="text-text-secondary">
            Select your delivery country to see the correct pricing, delivery
            options and item availability for your location.
          </Paragraph>
        </Stack>
      </Stack>

      <Box className="flex-1 overflow-scroll pb-5">
        <Box className="lg:columns-2">
          {feauturedCountries.map((item, i) => {
            return (
              <CountryOption
                key={i}
                item={item}
                handleClick={() => handleClick(item.iso2.toUpperCase())}
              />
            );
          })}
        </Box>

        <Divider className="my-6" />

        <Box className="lg:columns-2">
          {otherCountries.map((item, i) => {
            return (
              <CountryOption
                item={item}
                key={i}
                handleClick={() => handleClick(item.iso2.toUpperCase())}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export const NavigationLocaleSelectorTrigger = () => {
  const { currencyCode, shippingCountry } = useStoreContext();

  return (
    <>
      <Country countryIsoCode={shippingCountry} /> ({currencyCode})
    </>
  );
};

export const NavigationLocaleSelectorDrawer = () => {
  const { isUpdatingDeliveryCountry, isUpdatingCurrency } = useBasket();

  const { isOpen, handleClose } = useLocaleDrawer();

  const isUpdating = isUpdatingDeliveryCountry || isUpdatingCurrency;

  return (
    <Sheet open={isOpen} onOpenChange={handleClose}>
      <SheetContent side="bottom" className="h-screen w-screen">
        <SheetHeader title="Delivery country & currency" />
        <SheetBody className="relative grid flex-1 grid-cols-12 flex-col gap-y-4 overflow-scroll">
          {isUpdating && (
            <Box className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white opacity-75">
              <Loader />
            </Box>
          )}
          <Column className="col-span-12 lg:col-span-3">
            <CurrencySelector />
          </Column>
          <Column className="col-span-12 lg:col-span-9">
            <CountrySelector />
          </Column>
        </SheetBody>
      </SheetContent>
    </Sheet>
  );
};
