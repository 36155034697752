import { Box, IBoxProps } from '@/src/packages/components';
import { cn } from '@/src/packages/utils/cn';

type IStrongProps = IBoxProps;

export const Strong = (props: IStrongProps) => {
  const { className, ...rest } = props;

  return <Box as="strong" className={cn('font-medium', className)} {...rest} />;
};
