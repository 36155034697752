import { Button } from '../Button';
import { Heading } from '../Heading';
import { Box } from '../Box';
import { Markdown } from '../Markdown';
import Link from 'next/link';
import Image from 'next/image';
import { cn } from '@/src/packages/utils/cn';

export type IImageTextSplitBlock = {
  name: string;
  description: string;
  imageURL: string;
  isFlipped?: boolean;
  readMoreHref?: string;
  readMoreLabel?: string;
};

export const ImageTextSplitBlock = (props: IImageTextSplitBlock) => {
  const {
    name,
    description,
    imageURL,
    isFlipped,
    readMoreHref,
    readMoreLabel = 'Learn more',
  } = props;

  return (
    <Box className="grid grid-cols-12 gap-y-6">
      <Box
        className={cn(
          'relative col-span-12 aspect-[4/3] md:col-span-6 lg:aspect-square',
          {
            'md:order-2': isFlipped,
          },
        )}
      >
        <Image
          fill
          alt={`${name} – ${description}`}
          src={imageURL}
          className="object-cover"
          sizes="(max-width: 1024px) 100vw, 50vw"
        />
      </Box>
      <Box
        display="flex"
        paddingBottom={16}
        alignItems="center"
        colSpan={{ initial: 12, md: 6 }}
        className={cn('justify-center', {
          'md:order-1': isFlipped,
        })}
      >
        <Box className="w-full lg:max-w-lg">
          <Box className="space-y-5">
            <Box className="space-y-1">
              <Heading as="h2" className="font-serif text-3xl font-medium">
                {name}
              </Heading>
              <Markdown content={description} />
            </Box>

            {readMoreHref && (
              <Button
                variant="outline"
                color="black"
                size="default"
                asChild
                className="w-full lg:w-auto"
              >
                <Link href={readMoreHref}>{readMoreLabel}</Link>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
