import { CommerceUtils } from '@bitmap/contracts';

export const Country = ({
  countryIsoCode,
}: {
  countryIsoCode: string | undefined;
}) => {
  const country =
    CommerceUtils.country.getCountryByIsoCode(countryIsoCode)?.country;

  return <>{country}</>;
};
