import NextLink, { LinkProps } from "next/link";
import { HTMLProps } from "react";

type IInternalLink = LinkProps & Omit<HTMLProps<HTMLAnchorElement>, "ref">;

export const InternalLink = (props: IInternalLink) => {
  const { children, ...rest } = props;

  return <NextLink {...rest}>{children}</NextLink>;
};
