'use client';
import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { type VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { useZIndex } from '@/src/packages/theme';
import { Box } from '@/src/packages/components/ui/Box';
import { cn } from '@/src/packages/utils/cn';
import { tv } from 'tailwind-variants';
import { CloseButton } from '@/src/packages/components/ui/CloseButton';

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetPortal = ({
  className,
  ...props
}: SheetPrimitive.DialogPortalProps) => (
  <SheetPrimitive.Portal className={clsx(className)} {...props} />
);
SheetPortal.displayName = SheetPrimitive.Portal.displayName;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 bg-black/50',
      useZIndex({ zIndex: 'sheet' }),
      className,
    )}
    {...props}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = tv({
  base: 'fixed transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 flex flex-col bg-background-primary z-sheet',
  variants: {
    size: {
      default: '',
    },
    side: {
      left: 'inset-y-0 left-0 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left',
      right:
        'inset-y-0 right-0 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
      bottom:
        'bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
    },
  },
  compoundVariants: [
    {
      side: 'right',
      size: 'default',
      className: 'w-full max-w-3xl',
    },
  ],
});

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(
  (
    { side = 'right', size = 'default', className, children, ...props },
    ref,
  ) => (
    <SheetPortal>
      <SheetOverlay />
      <SheetPrimitive.Content
        ref={ref}
        className={sheetVariants({ side, className, size })}
        {...props}
      >
        {children}
      </SheetPrimitive.Content>
    </SheetPortal>
  ),
);
SheetContent.displayName = SheetPrimitive.Content.displayName;

export const SheetBody = (props: {
  className?: string;
  children: React.ReactNode;
}) => {
  const { className, ...rest } = props;

  return (
    <Box
      as="main"
      className={cn('flex-1 overflow-scroll p-4 lg:p-8', className)}
      {...rest}
    />
  );
};

const SheetHeader = ({
  className,
  title,
}: {
  className?: string;
  title: string;
}) => (
  <Box
    as="header"
    className={cn(
      'flex h-16 shrink-0 items-center border-b px-4 lg:px-8',
      className,
    )}
  >
    <SheetTitle>{title}</SheetTitle>
    <Box className="ml-auto">
      <SheetClose />
    </Box>
  </Box>
);
SheetHeader.displayName = 'SheetHeader';

const SheetClose = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Close>
>((props, ref) => (
  <SheetPrimitive.Close ref={ref} {...props} asChild>
    <CloseButton />
  </SheetPrimitive.Close>
));
SheetClose.displayName = SheetPrimitive.Close.displayName;

export const SheetFooter = (props: {
  testId?: string;
  className?: string;
  children: React.ReactNode;
}) => {
  const { className, ...rest } = props;

  return (
    <Box
      as="footer"
      className={cn(
        'bg-background-secondary shrink-0 border-t p-4 lg:p-8',
        className,
      )}
      {...rest}
    />
  );
};

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn('font-serif text-xl font-medium', className)}
    {...props}
  />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

export {
  Sheet,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
};
