import { useBorderColor } from '@/src/packages/theme/useBorderColor';
import { VariantProps, tv } from 'tailwind-variants';
import { Box, IBoxProps } from '../Box';
import { cn } from '@/src/packages/utils/cn';

const useDividerStyles = tv(
  {
    base: 'border-border-primary',
    variants: {
      thickness: {
        thick: '',
        thin: '',
      },
      direction: {
        horizontal: '',
        vertical: '',
      },
    },
    compoundVariants: [
      {
        direction: 'horizontal',
        thickness: 'thin',
        className: 'border-t',
      },
      {
        direction: 'horizontal',
        thickness: 'thick',
        className: 'border-t-2',
      },
      {
        direction: 'vertical',
        thickness: 'thin',
        className: 'border-r',
      },
      {
        direction: 'vertical',
        thickness: 'thick',
        className: 'border-r-2',
      },
    ],
  },
  { responsiveVariants: true },
);

type DividerVariants = VariantProps<typeof useDividerStyles> &
  VariantProps<typeof useBorderColor>;

type IDividerProps = Omit<IBoxProps, 'as'> & DividerVariants;

export const Divider = (props: IDividerProps) => {
  const {
    direction = 'horizontal',
    thickness = 'thin',
    className,
    ...rest
  } = props;

  return (
    <Box
      as="hr"
      className={cn(useDividerStyles({ direction, thickness }), className)}
      {...rest}
    />
  );
};
