import { ForwardedRef, forwardRef } from 'react';
import { Box, IBoxProps } from '../Box';
import { usePadding } from '@/src/packages/theme';
import { cn } from '@/src/packages/utils/cn';

type IContainer = IBoxProps;

export const Container = forwardRef(
  (props: IContainer, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, children, style, ...rest } = props;

    return (
      <Box
        ref={ref}
        className={cn(
          'w-full mx-auto max-w-[var(--container-width)]',
          usePadding({ paddingX: 'gutter' }),
          className
        )}
        style={{ ...style }}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

Container.displayName = 'Container';
