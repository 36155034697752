import { Box, IBoxProps } from '@/src/packages/components/ui/Box';

type IPageSectionProps = IBoxProps;

export const PageSection = (props: IPageSectionProps) => {
  const { children, ...rest } = props;

  return (
    <Box as="section" {...rest}>
      {children}
    </Box>
  );
};
