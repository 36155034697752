import { Box, IBoxProps } from '@/src/packages/components/ui/Box';
import { cn } from '@/src/packages/utils/cn';

export const PageSectionTitle = (props: IBoxProps) => {
  const { className, marginBottom = 8, ...rest } = props;

  return (
    <Box
      as="h2"
      className={cn('font-serif text-3xl font-medium', className)}
      marginBottom={marginBottom}
      {...rest}
    />
  );
};
