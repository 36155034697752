import { Box, IBoxProps } from '../Box';
import { VariantProps, tv } from 'tailwind-variants';
import { cn } from '@/src/packages/utils/cn';

export const useGridStyles = tv({
  base: 'grid grid-cols-12',
  variants: {
    gap: {
      default: 'gap-gutter',
    },
  },
});

type IGridProps = Omit<IBoxProps, 'gap'> & VariantProps<typeof useGridStyles>;

export const Column = (props: IBoxProps) => {
  return <Box {...props} />;
};

export const Grid = (props: IGridProps) => {
  const { children, className, gap = 'default', ...rest } = props;

  return (
    <Box className={cn(useGridStyles({ gap }), className)} {...rest}>
      {children}
    </Box>
  );
};
